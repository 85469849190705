import React, { useState,useContext,createContext } from 'react';
import './Login.css';
import {Form} from 'react-bootstrap';
import {Grid,Paper,Button} from '@material-ui/core';
import bg from '../../images/login_bg.jpg';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import { useNavigate,useHref,useLinkClickHandler} from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from '../../contexts/GlobalContext';

var sectionStyle = {
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${bg})`
      };

const Login = (props) =>{
    const { Url,user_id, setVariableUserId } = useContext(GlobalContext)
    const url =`${Url}/Library/userLogin`

    const [data,setData] = useState({
        type:"email",
        email:"",
        password:"",
    })

    function submit(e){
        e.preventDefault();
        axios.post(url,{
            type:data.type,
            email:data.email,
            password:data.password
        })
        .then(res =>{
            console.log(res)
            console.log(res.data)
            console.log(res.data.Code)
            if(res.data.Code === 200){
                alert(res.data.Message);
                setVariableUserId(res.data.user_id);
                console.log(user_id);
                window.sessionStorage.setItem("user", user_id);
                localStorage.setItem("user", res.data.user_id.toString());
                localStorage.setItem("name",res.data.name );
                localStorage.setItem("numberOfBooks",res.data.numberOfBooks.toString());
                setTimeout(() => {
                    if(res.data.admin === true){
                            window.location.href = "http://65.1.248.243";
                    }else{
                        navigate('/cDashboard');
                    }
                     
                  }, 60);
                
            }else{
                toast(res.data.Message);
            }
            
        })
        
                    }
                

    function handle(e){
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }


        let navigate = useNavigate();
        const paperStyle = {padding: 20, height: 'auto', width: '65vw', margin:'0px auto'}
    return(        
            <section style={sectionStyle}>
                <div className='login-area'>
                <Grid>
                <Paper elevation={10} style = {paperStyle} className = 'login-paper'>
                    <div className='login-container'>
                        <div className='login-logo-container'>
                        <img src = "https://www.iloveread.in/images/myhangout-logo.png" alt='hangout logo' className='login-logo'></img>
                        </div> 
                        <h2 className='title-lib-login'>My Hangout: Your Own Library</h2> 
                        <h6 className='title-login'>Log in to enter</h6> 
                        <h6 className='title-login1'>or Create a new FREE account</h6> 
                        <div className='login-textboxes'>
                        <Form.Floating className="mb-3">
                                <Form.Control
                                onChange={(e)=>handle(e)}
                                value={data.email}
                                id="email"
                                type="email"
                                placeholder="name@example.com"
                                />
                                <label htmlFor="floatingInputCustom">Email address</label>
                                </Form.Floating>
                                <Form.Floating>
                                <Form.Control
                                onChange={(e)=>handle(e)}
                                value={data.password}
                                id="password"
                                type="password"
                                placeholder="Password"
                                />
                                <label htmlFor="floatingPasswordCustom">Password</label>
                                
                                <Link className='login-forgot' to= '/forgot'>Forgot Password?</Link>

                                        {/* <Button variant='contained' size='small' className='login-btn' color = 'palette' id = 'login-btn'
                                        endIcon = {<div><FontAwesomeIcon className='signin-icon' icon={faSignOutAlt}></FontAwesomeIcon></div>}>
                                                <h5 className='login-btn-title'>LOGIN</h5> </Button> */}
                                                <div className='login-btn-area'>
                                                <Button variant='contained' size='medium' className='login-btn' id = 'login-btn' 
                                                endIcon = {<div><FontAwesomeIcon className='signin-icon' icon={faSignOutAlt}></FontAwesomeIcon></div>}
                                                 onClick={
                                                    (e) => submit(e)
                                                    }>
                                                <h5 className='login-btn-title'>LOGIN</h5> </Button>
                                                </div>
                                                

                                <Link className='signup-click' to= '/signup'>Don't have an account? Register</Link>
                                
                        </Form.Floating> 
                        
                        </div>        
                    </div>
                </Paper>
                </Grid>
                <ToastContainer />
            </div>
            </section>
            
    )
}

export default Login;