import React from "react";
import axios from "axios";
import {Form,Row,Col,Card, Container} from 'react-bootstrap';
import './BookList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle,faBookmark,faMinus} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';
import {Audio} from 'react-loader-spinner';
export default class SerendipityList extends React.Component{
    state = {
        books:[],
        Loading:false,
    };
   
    async componentDidMount(){
        this.setState({
            Loading:true})
        try{
            console.log(this.props.type)
            await axios.post(`${this.props.url}/Library/bookSearchAPI`,{
                "type":this.props.type,
                "genre":"",
                "name":"",
                "author":""
            }).then(
                res =>{
                    console.log(res.data);
                    this.setState({books: res.data.book_list});
                    this.setState({
                        Loading:false})
                });
            
                 }catch(error){
                    console.log(error);  
                    this.setState({
                        Loading:false})    
                 }
    }
// Function to clear complete cache data

     addToQueue = (e,book_title,catalouge_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(book_title);
        console.log(catalouge_id)
        axios.post(`${this.props.url}/Admin/addBooksToQueue`,{
            user_id:this.props.userID,
            book_name:book_title,
            catalogue_id:catalouge_id
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Code)
            if(res.data.Code === 200){
                toast(res.data.Message);
                this.componentDidMount()
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    addToBookmark = (e,catalogue_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(catalogue_id)
        axios.post(`${this.props.url}/Library/addBooksToBookmark`,{
            user_id:localStorage.getItem('user'),
            catalogue_id:catalogue_id
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Code)
            if(res.data.Code === 200){
                toast(res.data.Message);
                this.componentDidMount()
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    removeFromQueue = (e,book_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(book_id);
        axios.post(`${this.props.url}/Library/removeBooksFromQueue`,{
            // user_id:this.props.userID,
            book_id:book_id,
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Result)
            if(res.data.Result === 'Success'){
                this.componentDidMount()
                toast(res.data.Message); 
                    
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    removeFromBookmark = (e,bookmarked_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(bookmarked_id);
        axios.post(`${this.props.url}/Library/deleteBooksFromBookmarked`,{
            // user_id:this.props.userID,
            bookmarked_id:bookmarked_id,
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Result)
            if(res.data.Result === 'Success'){
                this.componentDidMount()
                toast(res.data.Message); 
                    
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    render(){
        return(
            
            <div>
                <Container  fluid>
                    <Row className="lib-row">
                        <h2>Librarian's Pick</h2> 
                        <Audio
                            type="Puff"
                            color="#2B7A78"
                            height={40}
                            width={40}
                            visible={this.state.Loading}
                        />
                        {this.state.books.map((book) => {

                        return(
                            
                            <Col xs={4} sm={3} lg={4} key = {book.isbn}>
                            <Card className="books-cards">
                                <Card.Img variant="top" src={book.book_image} className="books-image" />
                                <div className="books-overlay">
                                    <div className="books-overlay-text">{book.book_title}</div>
                                    <Row>
                                        <Col>
                                            {book.is_bookmarked === false ?<FontAwesomeIcon onClick={(e)=>this.addToBookmark(e,book.catalogue_id)} icon={faBookmark} className="books-overlay-bookmark"/>
                                            :<FontAwesomeIcon onClick={(e)=>this.removeFromBookmark(e,book.bookmarked_id)} icon={faBookmark} style={{color:'red'}} className="books-overlay-bookmark"/>}
                                        </Col>
                                        <Col>       
                                                {book.queued_book === false ? <FontAwesomeIcon onClick={(e)=>this.addToQueue(e,book.book_title,book.catalogue_id)} icon={faPlusCircle} className="books-overlay-atq"/>:
                                                    <FontAwesomeIcon onClick={(e)=>this.removeFromQueue(e,book.added_bk_queue_id)} icon={faMinus} className="books-overlay-atq"/>}
                                        </Col>
                                    </Row>
                                </div>
                                
                            </Card>
                            <h6>{book.book_title}</h6>
                            {/* <h6>{book.publisher!="NULL"?publisher:""}</h6>*/}
                            </Col> 
                        );
                            
                        })}
                        <ToastContainer />
                    </Row>
                </Container>
            </div>
                    
        )
    }


}