import React  from 'react';
import {Card,ListGroup,Button, CardGroup, Row, Col} from 'react-bootstrap';
import './ContactDetails.css';

function ContactDetails(){

    return(
        <div className='contact-row'>
            <h1>Contact Details</h1>
            <Card style={{ width: '100%',height:'auto',border:'2px solid black'}}>
                <div className='contact-details-info'>
                <h4>Important Contact Information</h4>
                    <div className='bangalore-myHangout'>
                        <h4>My Hangout</h4>
                        <h6>Library Name: My Hangout Library</h6>
                        <h6>Website: myhangout.in</h6>
                        <h6>Owner: Aanchal</h6>
                        <h6>Email: info@myhangout.in</h6>
                        <h6>Phone: +91-9980300816</h6>
                    </div>
                </div>
                
            </Card>
        </div>
        )
    }
    export default ContactDetails;