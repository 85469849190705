import React, { useState, useEffect, } from 'react';
import { Card, ListGroup, CardGroup, Row, Col, Container } from 'react-bootstrap';
import axios from "axios";
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Audio } from 'react-loader-spinner';
import { Select } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './ContactDetails.css';
var totalBooks = 0
var bookPriorityList = []

export default class MyQueue extends React.Component {

    // Function to update list on drop


    state = {
        queue: [],
        Loading: false,
        books: [],
        comments: "",
        priorityList: [],
        qstatus: ""
    };
    handleDrop = (droppedItem) => {
        // Ignore drop outside droppable container
        if (!droppedItem.destination) return;
        var updatedList = this.state.queue;
        // Remove dragged item
        console.log(droppedItem.source.index)
        console.log(droppedItem.destination.index)
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        // Add dropped item
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        // Update State
        console.log(updatedList)
        this.setState({ queue: updatedList });

        //Update to server
        console.log(this.props.userID)
        axios.post(`${this.props.url}/Library/addBookPriority`, {
            user_id: this.props.userID,
            list: this.state.queue
        })
            .then(res => {
                console.log(res.data)
                console.log(res.data.Result)
                if (res.data.Result === 'Success') {
                    toast(res.data.Message);
                } else {
                    toast(res.data.Message);
                }

            })
    };
    getQueueData = async () => {
        bookPriorityList.length = 0;
        this.setState({ Loading: true })

        try {
            await axios.post(`${this.props.url}/Library/GetBooksFromQueue`, {
                user_id: localStorage.getItem('user')
            }).then(
                res => {
                    console.log(res.data);
                    console.log(this.props.userID)
                    this.setState({ queue: res.data.queued_books, Loading: false });
                    this.books = res.data.queued_books.map(ele => ele.book_id);
                    console.log("==================================");
                    console.log(this.books);
                    console.log(this.books.length);
                    totalBooks = this.books.length;
                    console.log(totalBooks)
                    this.setState({ qstatus: res.data.status })

                });

        } catch (error) {
            console.log(error);
            this.setState({ Loading: false });
        }
    }
    /* clearCacheData = () => {
         window.caches.keys().then((names) => {
         names.forEach((name) => {
             window.caches.delete(name);
         });
         });
     }*/
    async componentDidMount() {
        // this.clearCacheData();
        this.getQueueData();
    }

    removeFromQueue = (e, book_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(book_id);
        if (window.confirm('Are you sure to remove book from queue ?')) {
            // Save it!
            console.log('Yes.');
        } else {
            // Do nothing!
            console.log('No.');
            return;
        }
        axios.post(`${this.props.url}/Library/removeBooksFromQueue`, {
            user_id: localStorage.getItem('user'),
            book_id: book_id,
        })
            .then(res => {
                console.log(res.data)
                console.log(res.data.Result)
                if (res.data.Result === 'Success') {
                    this.getQueueData()
                    alert(res.data.Message);

                } else {
                    alert(res.data.Message);
                }

            })


    }


    createSelectItems() {
        let items = [];
        console.log("List length:" + bookPriorityList.length)
        var permittedValues = bookPriorityList.map(value => value.index);
        console.log(permittedValues)
        items.push(<option key='0' value='0'>0</option>);
        for (let i = 1; i <= this.state.queue.length; i++) {
            if (permittedValues.includes((i).toString(), 0)) {
                //console.log(i)
                //console.log("matched");
                continue;
            }
            else {
                //console.log(i)
                //console.log("not matched");
                items.push(<option key={i} value={i}>{i}</option>);
            }
        }
        // console.log(items);
        return items;
    }


    onDropdownSelected = (e, book_id) => {
        if (bookPriorityList && bookPriorityList.length > 0) {
            for (var i = 0; i < bookPriorityList.length; i++) {
                if (bookPriorityList[i].index === e.target.value) {
                    toast("Priority is already chosen for other book");
                    return true;
                }
            }
        }
        if (e.target.value == 0) {
            toast("Priority 0 can not be chosen for any book");
            return true;
        }
        const b = {
            "index": e.target.value,
            "id": book_id
        };
        // bookPriorityList.push(b);

        this.setState({ priorityList: this.state.priorityList })
        //this.setState({ priorityList: [...this.state.priorityList, b] })
        bookPriorityList.push(b);
        console.log("THE VAL", e.target.value);
        //console.log("THE VAL", this.priorityList);
        console.log("THE VAL", bookPriorityList);
        /*   e.preventDefault();
           console.log(this.props.userID)
           console.log(book_id);
           axios.post(`${this.props.url}/Library/addBookPriority`, {
               user_id:this.props.userID,
               book_id: book_id,
               priority:e.target.value
           })
               .then(res => {
                   console.log(res.data)
                   console.log(res.data.Result)
                   if (res.data.Result === 'Success') {
                       toast(res.data.Message);
                   } else {
                       toast(res.data.Message);
                   }
       
               })*/
        //here you will see the current selected value of the select input
    }
    searchId(id) {
        if (bookPriorityList && bookPriorityList.length > 0) {
            for (var i = 0; i < bookPriorityList.length; i++) {
                if (bookPriorityList[i].id === id) {
                    return true;
                }
            }
        }
        return false
    }
    searchPriority(id) {
        if (bookPriorityList && bookPriorityList.length > 0) {
            for (var i = 0; i < bookPriorityList.length; i++) {
                if (bookPriorityList[i].id === id) {
                    return bookPriorityList[i].index;
                }
            }
        }
        return 0
    }
    handleComments(e) {
        console.log(e.target.value)
        this.comments = e.target.value
    }
    requestForDelivery = (e) => {
        e.preventDefault();
        console.log(this.comments)
        if (this.books.length === 0) {
            alert("No Books for request");
            return
        }
        var numberOfBooks = parseInt(localStorage.getItem("numberOfBooks"));
        console.log(numberOfBooks);
        console.log(this.books.length);
        console.log(this.props.userID)
        var allowedNumber = 10;
        if (numberOfBooks == 2) {
            allowedNumber = 10;
        }
        if (numberOfBooks == 4) {
            allowedNumber = 16;
        }
        if (numberOfBooks == 6) {
            allowedNumber = 20;
        }

        if (this.books.length < allowedNumber) {
            alert("As per your plan, Please add minimum " + allowedNumber.toString() + " books for delivery request.Thanks");
            console.log("T1")
            return;
        }
        if (!this.comments) {
            this.comments = ""
        }
        console.log("Sending to server...")
        axios.put(`${this.props.url}/Admin/ChangeStatusOfBooksInQueue`, {
            user_id: localStorage.getItem('user'),
            book_id: this.books,
            comments: this.comments,
            request_list: bookPriorityList
        })
            .then(res => {
                console.log(res.data)
                console.log(res.data.Result)
                if (res.data.Result === 'Success') {
                    this.getQueueData()
                    alert("Requested For Delivery");

                } else {
                    alert(res.data.Message);
                }

            })
    }


    //=============card movement function===============
    render() {

        return (
            <div>
                <Container fluid>
                    <Row className='myqueue-row'>
                        <Col xs={7}><h2>My Queue {this.state.queue.length} Books, Queue Status is {this.state.qstatus} </h2>
                            <Audio
                                type="Puff"
                                color="#2B7A78"
                                height={40}
                                width={40}
                                visible={this.state.Loading}

                            />
                        </Col>
                        <Col xs={5}>{<Button style={{ color: 'white', backgroundColor: 'green' }} onClick={
                            (e) => this.requestForDelivery(e)
                        }>Request Delivery</Button>}
                        </Col>
                    </Row>
                    <Row className='myqueue-comment-box'>
                        <Col xs={12}>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Delivery comment [Max 50 Characters]</label>
                                <textarea
                                    className="form-control"
                                    id="exampleFormControlTextarea1"
                                    rows="3"
                                    maxlength="50"
                                    onChange={(e) => this.handleComments(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    <br></br><br></br>
                    <Row className='myqueue-card-row'>
                        <Col xs={12}>
                            <div>
                                <DragDropContext onDragEnd={this.handleDrop}>
                                    <Droppable droppableId="list-container">
                                        {(provided) => (
                                            <div

                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {this.state.queue.map((item, index) => (
                                                    <Draggable key={item.book_id} draggableId={item.book_id.toString()} index={index}>
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}

                                                            >
                                                                <Card className='queue-crd' key={item.book_id}>
                                                                    <ListGroup variant="flush">
                                                                        <ListGroup.Item>

                                                                            <Row>
                                                                                <Col xs={1} sm={1}>{index + 1}</Col>
                                                                                <Col xs={1} sm={1}>
                                                                                    <Card.Img variant="top" className='queue-crd-img' src={item.book_images} />
                                                                                </Col>

                                                                                <Col xs={10} sm={10}>
                                                                                    <Card.Body>
                                                                                        <Row>
                                                                                            <Col xs={9} sm={8}>
                                                                                                <Card.Title className='crd-txt-title'>{item.book_name}, Pages: {item.pages}</Card.Title>
                                                                                            </Col>
                                                                                            <Col xs={3} sm={4}>
                                                                                                {item.status === 'confirmed' ? <div></div> : <Button className='crd-btn' onClick={
                                                                                                    (e) => this.removeFromQueue(e, item.book_id)
                                                                                                }>Remove</Button>}
                                                                                            </Col>

                                                                                        </Row>
                                                                                    </Card.Body>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row>
                                                                                <Col xs={12} sm={12}>
                                                                                    <label className='crd-txt'>Current Status:{item.status}, Add Timestamp:{item.time}, {item.book_status} in Library</label>
                                                                                </Col>
                                                                            </Row>
                                                                        </ListGroup.Item>
                                                                    </ListGroup>
                                                                </Card>

                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                        </Col>
                    </Row>
                    { }
                </Container>
            </div>
        )
    }
}
