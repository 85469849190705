import React from 'react';
import './Pricing.css';
import {Card,ListGroup,Button, CardGroup} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function Pricing() {

    let navigate = useNavigate();
    return (
        <div id='price'>
        <div className='pricing-main'>
        <div className = 'pricing-container'>
            <div className = 'pricing-title'>
                <h2 className = 'titlep1'>PRICING.</h2>
            </div>
            <div className = 'verticalp-line-divider'></div>
            <hr className = 'pricing-hz-line'></hr>
            {/* <div className = 'pricing-details'>
                <p className = 'pricing-details-p'>
                    My Hangout is a community reading space, a library of books, 
                    and an activity center based out of Bellandur, Bangalore. 
                    We deliver books to your home/office too!
                </p>
            </div> */}
        </div>
        
        <div className='pricing-cards'>
        <CardGroup>
            <Card style={{ width: 'auto', height: 'auto'}} className='pricing-card-others'>
                <Card.Header className='card-price-title'>HABIT</Card.Header>
                    <ListGroup variant="flush">
                        <ListGroup.Item className='pricing'>
                            <h1 className='price-text'>Rs<span> </span>1200</h1>
                            <h4 className='price-text'>for three months</h4>
                        </ListGroup.Item>
                        <ListGroup.Item className='pricing-list'>
                        <h4 className='price-text'>2 books at a time</h4>
                        </ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Free home Delivery</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>One Time Registration fee: Rs. 500</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Refundable deposit: Rs. 500</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Subscription Fees:</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Quarterly: Rs.1200</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Half yearly: Rs. 2200</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Annual: Rs. 4000</ListGroup.Item>
                    </ListGroup>
                    <Button variant="primary" className='sign-up-btn-price' onClick={() => {navigate("/login")}}>SIGN UP</Button>
            </Card>
            <div className='card-gap'></div>
            <Card style={{ width: 'auto', height: 'auto' }} className='pricing-card-middle'>
                <Card.Header className='card-price-title-middle'>HUNGRY</Card.Header>
                    <ListGroup variant="flush">
                    <ListGroup.Item className='pricing'>
                            <h1 className='price-text'>Rs<span> </span>1600</h1>
                            <h4 className='price-text'>for three months</h4>
                        </ListGroup.Item>
                        <ListGroup.Item className='pricing-list'>
                        <h4 className='price-text'>4 books at a time</h4>
                        </ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Free home Delivery</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>One Time Registration fee: Rs. 500</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Refundable deposit: Rs. 1000</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Subscription Fees:</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Quarterly: Rs.1600</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Half yearly: Rs. 3000</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Annual: Rs. 5600</ListGroup.Item>
                    </ListGroup>
                    <Button variant="primary" className='sign-up-btn-pricem' onClick={() => {navigate("/login")}}>SIGN UP NOW</Button>
            </Card>
            <div className='card-gap'></div>
            <Card style={{ width: 'auto',height: 'auto' }} className='pricing-card-others'>
                <Card.Header className='card-price-title'>MONSTER</Card.Header>
                    <ListGroup variant="flush">
                    <ListGroup.Item className='pricing'>
                            <h1 className='price-text'>Rs<span> </span>2000</h1>
                            <h4 className='price-text'>for three months</h4>
                        </ListGroup.Item>
                        <ListGroup.Item className='pricing-list'>
                        <h4 className='price-text'>6 books at a time</h4>
                        </ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Free home Delivery</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>One Time Registration fee: Rs. 500</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Refundable deposit: Rs. 1500</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Subscription Fees:</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Quarterly: Rs.2000</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Half yearly: Rs. 3800</ListGroup.Item>
                        <ListGroup.Item className='price-list-text'>Annual: Rs. 7200</ListGroup.Item>
                    </ListGroup>
                    <Button variant="primary" className='sign-up-btn-price' onClick={() => {navigate("/login")}}>SIGN UP</Button>
            </Card>
        </CardGroup>
        </div>
        </div>
        <div className = 'lfb-container'>
            <div className = 'lfb-title'>
                <h2 className = 'titlelfb'>LATEST FROM BLOG.</h2>
            </div>
            <div className = 'vertical-line-divider-lfb'></div>
            <hr className = 'lfb-hz-line'></hr>
            <div className = 'lfb-details'>
                <p className = 'lfb-details-p'>
                We publish articles on book reading, book lists and activities that happen at Hangout.
                </p>
            </div>
        </div>
        </div>
    )
}

export default Pricing;
