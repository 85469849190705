import React from "react";
import NavBar from "../NavBar";
import CarouselContainer from "../Carousel";
import Services from "./Services";
import Cards from './Card';
import Features from './Website_features';
import HowItWorks from './How_it_Works';
import AboutUs from './AboutUs';
import Pricing from './Pricing';
import ContactUs from './ContactUs';

function WebsiteHome(){

    return(
        <>
        <NavBar/>
        <CarouselContainer/>
        <div className = 'intro'>
            <h4>Besides books, we offer consultations and support to parents to work on learning and behavioral issues in children.</h4>
        </div>
        <Services/>
        <Cards/>
        <HowItWorks/>
        <Features/>
        <AboutUs/>
        <Pricing/>
        <ContactUs/>
        </>
    )
}

export default WebsiteHome;