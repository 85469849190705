import React,{useState,useContext}from 'react';
import  './SignUp.css';
import {Form,Row,Col,FloatingLabel} from 'react-bootstrap';
import {Grid,Paper,Button} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import registerImage from '../../images/register.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from '../../contexts/GlobalContext';
var sectionStyle = {
    width: "100%",
    height: "auto",
  };
 
function SignUp(){
    const { Url,user_id, setVariableUserId } = useContext(GlobalContext)
    const url = `${Url}/Library/userSignup`
    const [data,setData] = useState({
        first_name:"",
        last_name:"",
        password:"",
        email:"",
        dob:"",
        primary_mobile:"",
        alternate_mobile:0,
        gender:"",
        main_address:"",
        landmark:"",
        pincode: "",
        city:"",
        state:""
    })

    function submit(e){
        e.preventDefault();
        if((data.first_name && data.last_name && data.password && data.email && data.dob && 
            data.primary_mobile && data.gender && data.main_address && data.gender && 
            data.landmark && data.pincode && data.city && data.state) === ""){
                    toast("All fields are Mandatory,Please fill the details");
            }else{
                axios.post(url,{
                    full_name: data.first_name + data.last_name,
                    password: data.password,
                    email: data.email,
                    dob: data.dob,
                    primary_mobile: data.primary_mobile,
                    alternate_mobile:0,
                    gender: data.gender,
                    main_address: data.main_address,
                    landmark: data.landmark,
                    pincode: data.pincode,
                    city: data.city,
                    state: data.state
                })
                .then(res =>{
                    console.log(res)
                    if(res.data.Code === 200){
                        toast("Account Created Successfully,Please Login");
                navigate('/login');
            }else{
                toast(res.data.Message);
            }
                })
            }
        
    }

    function handle(e){
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }    

    let navigate = useNavigate();
    const paperStyle = {padding: 20, height: 'auto', width: '75vw', margin:'auto auto'}
    return(
        <section style={sectionStyle}>
            <div className='signup-area'>
                
                <div className='signup-left-section'>
                            <div className='signup-logo-container'>
                                <img src = "https://www.iloveread.in/images/myhangout-logo.png" alt='hangout logo' className='signup-logo'></img> 
                             </div>  
                                <h6 className='title-lib-signup'>Your Own Library</h6> 
                            <div className='register-logo-container'> 
                                <img src = {registerImage} alt='register' className='register-image'></img> 
                            </div> 
                </div>
                 
                    <div className='signup-card-area'>      
                    <Grid>
                    <Paper elevation={10} style = {paperStyle} className = 'signup-paper'>
                        <div className='signup-container'>
                            <h4 className='title-signup'>Create Your Account</h4>  
                            <div className='signup-textboxes'>
                            
                            <Row>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="first_name"
                                    type="name"
                                    placeholder="name"
                                    onChange={(e)=>handle(e)}
                                    value={data.first_name}
                                    />
                                    <label htmlFor="first_name">First Name</label>
                                    </Form.Floating>
                                </Col>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="last_name"
                                    type="name"
                                    placeholder="name"
                                    onChange={(e)=>handle(e)}
                                    value={data.last_name}
                                    />
                                    <label htmlFor="last_name">Last Name</label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Form.Floating className="mb-3">
                                    <Form.Control className='textbox-styling'
                                    id="email"
                                    type="email"
                                    placeholder="name@example.com"
                                    onChange={(e)=>handle(e)}
                                    value={data.email}
                                    />
                                    <label htmlFor="floatingInputCustom">Email address</label>
                                    </Form.Floating>
                                    <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="password"
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e)=>handle(e)}
                                    value={data.password}
                                    />
                                    <label htmlFor="floatingPasswordCustom">Password</label>
                                    </Form.Floating>
                            <Row>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="dob"
                                    type="date"
                                    placeholder="Date of Birth"
                                    onChange={(e)=>handle(e)}
                                    value={data.dob}
                                    />
                                    <label htmlFor="dob">Date of Birth</label>
                                    </Form.Floating>
                                </Col>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="primary_mobile"
                                    type="mobile"
                                    placeholder="Mobile No."
                                    onChange={(e)=>handle(e)}
                                    value={data.primary_mobile}
                                    />
                                    <label htmlFor="primary_mobile">Mobile No.</label>
                                    </Form.Floating>
                                </Col>
                                <Col md>
                                    <FloatingLabel className = 'textbox-styling-3' controlId="gender" label="Gender">
                                    <Form.Select aria-label="Floating label select example"
                                    onChange={(e)=>handle(e)}
                                    value={data.gender}>
                                        <option>Select Gender</option>
                                        <option value="1">Male</option>
                                        <option value="2">Female</option>
                                        <option value="3">Other</option>
                                    </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                                    <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="main_address"
                                    type="address"
                                    placeholder="Address"
                                    onChange={(e)=>handle(e)}
                                    value={data.main_address}
                                    />
                                    <label htmlFor="main_address">Address</label>
                                    </Form.Floating>
                                    <h6 className='note-delivery'>*Note: We deliver in the radius of 15 kms from Bellandur</h6>

                            <Row>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="state"
                                    type="state"
                                    placeholder="state"
                                    onChange={(e)=>handle(e)}
                                    value={data.state}
                                    />
                                    <label htmlFor="state">State</label>
                                    </Form.Floating>
                                </Col>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="city"
                                    type="city"
                                    placeholder="city"
                                    onChange={(e)=>handle(e)}
                                    value={data.city}
                                    />
                                    <label htmlFor="city">City</label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="landmark"
                                    type="landmark"
                                    placeholder="landmark"
                                    onChange={(e)=>handle(e)}
                                    value={data.landmark}
                                    />
                                    <label htmlFor="landmark">Landmark</label>
                                    </Form.Floating>
                                </Col>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling'
                                    id="pincode"
                                    type="pincode"
                                    placeholder="pincode"
                                    onChange={(e)=>handle(e)}
                                    value={data.pincode}
                                    />
                                    <label htmlFor="pincode">Pincode</label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                                    

                                      <div className='signup-btn-area'>
                                            <Button variant='contained' size='medium' className='signup-btn' id = 'signup-btn' onClick={(e) => submit(e)}
                                                endIcon = {<div><FontAwesomeIcon className='signin-icon' icon={faSignOutAlt}></FontAwesomeIcon></div>}>
                                            <h5 className='signup-btn-title'>REGISTER</h5> </Button>
                                        </div>
                                                    

                                    <Link className='signup-click' to= '/login'>Already have an account? Login</Link>
                                    
                             
                            </div>        
                        </div>
                    </Paper>
                    </Grid>
                </div>
                
                {/* </div> */}
                <ToastContainer />
            </div>
        </section>
    )
}
export default SignUp;