import React, { useState,useEffect,} from 'react';
import {Card,ListGroup,Button, CardGroup, Row, Col, Container} from 'react-bootstrap';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './ContactDetails.css';

export default class MyHistory extends React.Component{

    state = {
        hist:[]
    };
    getHistoryData = async() =>{
        try{
            await axios.post(`${this.props.url}/Admin/GetBorrowedBooks`,{
                user_id:localStorage.getItem('user')
            }).then(
                res =>{
                    console.log(res.data);
                    console.log(this.props.userID)
                    this.setState({hist: res.data.borrowed_bks});
                });
            
                 }catch(error){
                    console.log(error);      
                 }
    }

    async componentDidMount(){
        
        this.getHistoryData();
    }

    
    render(){
    return(
        <div>
            <Container>
                <Row className='myhistory-row'>
                    <h2>My History(Delivered Books are in green)</h2> 
                    <Col xs={12}>
                    <div className='cdashboard-queue-list'>
                        {this.state.hist.map((history_books) => {
                            return(
                    <Card style={{ width: 'auto',height:'auto' }} key= {history_books.barcode}>
                        <ListGroup variant="flush">
                            <ListGroup.Item>
                            {history_books.returned_on == 'not_returned'?
                            <Card style={{ width: 'auto',height:'auto', backgroundColor:'green' }}>
                                
                                    <h5 style={{fontWeight:'bold', color:'white'}}>{history_books.book_name}</h5>
                                    <h6 style={{color:'white'}}>Delivered On: {history_books.delivered_on}</h6>
                                    <h6 style={{color:'white'}}>Returned On: {history_books.returned_on}</h6>
                                </Card>
                                :
                                <Card style={{ width: 'auto',height:'auto', backgroundColor:'yellow' }}>
                                
                                <h5 style={{fontWeight:'bold'}}>{history_books.book_name}</h5>
                                <h6>Delivered On: {history_books.delivered_on}</h6>
                                <h6>Returned On: {history_books.returned_on}</h6>
                            </Card>
                        }
                            </ListGroup.Item>
                        </ListGroup>
                        </Card>
                            );})}
                            <ToastContainer />
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        )
    }
}
