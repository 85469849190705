import React, { createContext, useState } from 'react'
export const GlobalContext = createContext()
const GlobalContextProvider = (props) => {
    const [user_id, setVariableUserId] = useState('')
    const Url = "http://15.207.107.128:6200"
    return (
         <GlobalContext.Provider 
            value={{
                setVariableUserId,
                user_id,
                Url
             }}>
               {props.children}
         </GlobalContext.Provider>
    )
}
export default GlobalContextProvider