import React from 'react';
import './Website_features.css';
import 'react-bootstrap';



function Features() {
    return (
        <div id='features'>
            <div className = 'card-container2'>
        <div className = 'container mt-3'>
            <div className = 'row'>
                <div className = 'col-md-4'>
                    <div className = 'card shadow-lg'>
                    <img src = 'http://myhangout.in/blog/wp-content/uploads/2013/05/Girl-with-books-000055436212_Small2-290x280.jpg'
                    alt = 'Find Books'/>
                    <div className = 'card-body-features'>
                        <h3 className = 'card-title'>FIND BOOKS</h3>
                        <p className = 'card-details'>

                        You can discover the books you want by – search, category listing, age-wise listing, special lists, serendipity, recently returned and popular.

                        We use the super user-friendly library.services online library platform. The platform has a very powerful and effective search function making it very easy to find the right book. 
                        You can also discover the books you want by – search, category listing, age-wise listing, special lists, serendipity, recently returned and popular.

                        All these features are arranged neatly in your menu on the left once you login.
                        </p>
                    </div>
                    </div>    
                </div>
                <div className = 'col-md-4'>
                    <div className = 'card shadow-lg'>
                    <img src = 'http://myhangout.in/blog/wp-content/uploads/2013/05/Students-Asian-read-the-book-at-the-library-000076911485_Small2-290x280.jpg' 
                    alt = 'Add Queue'/>
                    <div className = 'card-body-features'>
                        <h3 className = 'card-title'>ADD TO QUEUE</h3>
                        <p className = 'card-details'>
                        Books you like can be added to your own custom lists – My Bookmarks (books I want to read later) and My Queue (books I want to read now).

                        Click the heart icon on any book to add it to your bookmark list and the plus icon to add it to your queue. Your queue is the books you want now, and your bookmark list is the books you want to read later. You can add as many books you want to your queue and to your bookmark list. We’ve known members who add 30-40 books to their queue and 100-120 books to their bookmark list.

                        Once there are enough books in your reading queue (as per your plan), you may ask for a delivery.
                        </p>
                    </div>
                    </div>    
                </div>
                <div className = 'col-md-4'>
                    <div className = 'card shadow-lg'>
                    <img src = 'http://myhangout.in/blog/wp-content/uploads/2013/05/Internet-Book-Delivery-000024880085_Small2-290x280.jpg' 
                    alt = 'Delivery'/>
                    <div className = 'card-body-features'>
                        <h3 className = 'card-title'>ASK FOR A DELIVERY</h3>
                        <p className = 'card-details'>
                        Once you’ve added books to My Queue, click on Ask for Delivery. Click Confirm on the My Queue availability screen and we’ll deliver the top available books in your queue on your next scheduled delivery date to your home or office. When we deliver your books, you may return the earlier ones.

                        To check for your next delivery date, go to My Subscription / My Account page. If your delivery schedule is not set on the website, then email us and we’ll fix it.

                        You’ll need to ask for delivery 24 hours before your delivery days.
                        </p>
                    </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>

        </div>
    )
}

export default Features;