import React from 'react';
import WebsiteHome from './components/First_Page/WebsiteHome';
import {BrowserRouter as Router,Routes,Route,Link,NavLink} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login_Module/Login';
import ClientDashboard from './components/Client_Dashboard/ClientDashboard';
import SignUp from './components/SignUp_Module/SignUp';
import Password from './components/ForgotPassword_module/Password';
import SearchList from './components/Client_Dashboard/SearchList';
import GlobalContextProvider from './contexts/GlobalContext';
import { LoginContext } from './components/Login_Module/Login';

function App() {
  return (
    <>
    <GlobalContextProvider>
    <Router>
    <Routes>
          <Route exact path ="/" element = {<WebsiteHome/>}/>
          <Route exact path ="/login" element = {<Login/>}/>
          <Route exact path ="/cDashboard" element = {<ClientDashboard/>}/>
          <Route exact path ="/signup" element = {<SignUp/>}/>
          <Route exact path ="/forgot" element = {<Password/>}/>
    </Routes>
    </Router>
    </GlobalContextProvider>
    </>
  );
}

export default App;
