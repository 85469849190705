import React from 'react';
import * as fasIcons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SidebarData1 = [
  {
    title: 'Home',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faHome} />,
    cName: 'nav-text'
  },
  {
    title: 'Librarian\'s Pick',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faRandom} />,
    cName: 'nav-text'
  },
  {
    title: 'New Arrivals',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faExternalLinkAlt} />,
    cName: 'nav-text'
  },
 /* {
    title: 'Genres',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faArrowCircleRight} />,
    cName: 'nav-text',
  },*/
  {
    title: 'Recently Returned',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faArrowCircleLeft} />,
    cName: 'nav-text'
  },
];

export const SidebarData2 = [
  {
    title: 'My Queue',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faCartArrowDown} />,
    cName: 'nav-text'
  },
  {
    title: 'My History',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faHistory} />,
    cName: 'nav-text'
  },
  {
    title: 'My Subscription',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faUserClock} />,
    cName: 'nav-text'
  },
  {
    title: 'My Bookmarks',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faBookmark} />,
    cName: 'nav-text'
  }
];

export const SidebarData3 = [
  {
    title: 'Contact Details',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faInfo} />,
    cName: 'nav-text'
  },
  {
    title: 'FAQ/Help',
    path: '#',
    icon: <FontAwesomeIcon icon={fasIcons.faQuestion} />,
    cName: 'nav-text'
  },
];