import React,{useState,useContext}from 'react';
import  './Password.css';
import {Form,Row,Col,FloatingLabel} from 'react-bootstrap';
import {Grid,Paper,Button} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import registerImage from '../../images/register.png';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalContext } from '../../contexts/GlobalContext';
var sectionStyle = {
    width: "100%",
    height: "auto",
  };
 
function Password(){
    const { Url,user_id, setVariableUserId } = useContext(GlobalContext)
    const url = `${Url}/Library/forgotPassword`
    const [data,setData] = useState({
        email:"",
    })

    function submit(e){
        e.preventDefault();
        if((data.email ) === ""){
                    toast("Email is Mandatory,Please fill the detail");
            }else{
                axios.post(url,{

                    email: data.email,
                  
                })
                .then(res =>{
                    console.log(res)
                    if(res.data.Code === 200){
                        alert(res.data.Message);
                        navigate('/login');
            
            }else{
                toast(res.data.Message);
            }
                })
            }
        
    }

    function handle(e){
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }    

    let navigate = useNavigate();
    const paperStyle = {padding: 20, height: 'auto', width: '75vw', margin:'auto auto'}
    return(
        <section style={sectionStyle}>
            <div className='signup-area'>
                
                <div className='signup-left-section'>
                            <div className='signup-logo-container'>
                                <img src = "https://www.iloveread.in/images/myhangout-logo.png" alt='hangout logo' className='signup-logo'></img> 
                             </div>  
                                <h6 className='title-lib-signup'>Your Own Library</h6> 
                            
                </div>
                 
                    <div className='signup-card-area'>      
                    <Grid>
                    <Paper elevation={10} style = {paperStyle} className = 'signup-paper'>
                        <div className='signup-container'>
                            <h4 className='title-signup'>Forgot Password</h4>  
                            <div className='signup-textboxes'>
                            
                            
                            <Form.Floating className="mb-3">
                                    <Form.Control className='textbox-styling'
                                    id="email"
                                    type="email"
                                    placeholder="name@example.com"
                                    onChange={(e)=>handle(e)}
                                    value={data.email}
                                    />
                                    <label htmlFor="floatingInputCustom">Email address</label>
                                    </Form.Floating>

                                      <div className='signup-btn-area'>
                                            <Button variant='contained' size='medium' className='signup-btn' id = 'signup-btn' onClick={(e) => submit(e)}
                                                endIcon = {<div><FontAwesomeIcon className='signin-icon' icon={faSignOutAlt}></FontAwesomeIcon></div>}>
                                            <h5 className='signup-btn-title'>Submit</h5> </Button>
                                        </div>
                                        <Link className='signup-click' to= '/login'>Login</Link>
                            </div>        
                        </div>
                    </Paper>
                    </Grid>
                </div>
                
                {/* </div> */}
                <ToastContainer />
            </div>
        </section>
    )
}
export default Password;