import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom';
// import { Button } from './Button';
import './NavBar.css';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';


function NavBar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);

    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        console.log(window.innerWidth);
        if (window.innerWidth <= 1160) {
            setButton(false);
        } else {
            setButton(true);
        }
    }


    useEffect(() => {
        showButton();

    }, []);

    let navigate = useNavigate();

    // const routeChange = () =>{ 
    //   let path = `./Login_Module/Login`; 
    //   navigate.push(path);
    // }

    window.addEventListener('resize', showButton);
    function test() {
        document.getElementById('btn-col').click();
    }


    return (
        <>
            <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light">
                <div class="container-fluid">
                    <a class="navbar-brand me-2" href="#">
                        <img
                            src="http://139.59.3.2/blog/wp-content/uploads/2016/08/rsz_1hangout_final304x40copy.png"
                            height="40"
                            alt="My Hangout Library"
                            loading="lazy"
                        />
                    </a>
                    {  /*      <button
                        class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarTogglerDemo02"
                        aria-controls="navbarTogglerDemo02"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        id='btn-col'
                    >
                        <i class="fas fa-bars"></i>
    </button> */}
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0" onClick={handleClick}>
                            <li class="nav-item">
                                <Link to="services" spy={true} smooth={true} offset={-100} duration={100} className='nav-links' onClick={test}>
                                    SERVICES
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="features" spy={true} smooth={true} offset={-100} duration={100} className='nav-links' onClick={closeMobileMenu}>
                                    FEATURES
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="hiw" spy={true} smooth={true} offset={-100} duration={100} className='nav-links' onClick={closeMobileMenu}>
                                    HOW IT WORKS
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="price" spy={true} smooth={true} offset={-100} duration={100} className='nav-links' onClick={closeMobileMenu}>
                                    PRICING
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="about" spy={true} smooth={true} offset={-100} duration={100} className='nav-links' onClick={closeMobileMenu}>
                                    ABOUT US
                                </Link>
                            </li>
                            <li class="nav-item">
                                <Link to="contact" spy={true} smooth={true} offset={50} duration={100} className='nav-links' onClick={closeMobileMenu}>
                                    CONTACT US
                                </Link>
                            </li>

                        </ul>


                    </div>
                    <Button
                        variant='outlined'
                        onClick={localStorage.getItem("user") !== "" ? () => { navigate("/login") } : () => { navigate("/login") }}
                    >LOGIN / SIGN UP</Button>
                </div>
            </nav>
        </>

    )
}

export default NavBar;
