import React  from 'react';
import {Card,ListGroup,Button, CardGroup, Row, Col} from 'react-bootstrap';
import './Help.css';

function Help(){

    return(
        <div className='help-row'>
            <h6>
            Please find the most frequently asked questions and their answers in the section below. 
            If you need any further clarification, contact us (email/phone) and we'll be more than happy to answer your queries. Contact information is available here.

            </h6>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What is MyHangout.in?</h2>
                <h6>MyHangout.in is the online platform of Hangout Library. 
                    Through this, you can request a hard copy of the book you want to read. 
                    The website is simple, user-friendly, and very effective. 
                    You become a member of MyHangout.in, select the books you want to read from the online catalogue, and we deliver the book to you.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How many titles do you have in your catalogue?</h2>
                <h6>We have more than 15000 titles which have been chosen very carefully. 
                    The catalogue is extensive and covers all the popular titles. 
                    On top of it, we add books every month.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Do you have regional language books in your catalogue?</h2>
                <h6>We used to have. 
                    We’ve discontinued them for now. 
                    We have a few short stories for children in Hindi.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Why should I become a member of MyHangout.in?</h2>
                <h6>The simple reason is that you are a booklover and you will get to read from our huge collection every week. 
                    Other reasons are 
                    (a) Smooth delivery 
                    (b) Free delivery 
                    (c) Multiple plans to suit your requirements 
                    (d) Low monthly charges 
                    (e) Guidance from child educators in choosing books</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What are the work timings?</h2>
                <h6>The website is always available for you to surf, take membership, and request books. 
                    Office timings are from 10:30 AM to 7:30 PM. We do not work on National Holidays.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What is your current coverage area?</h2>
                <h6>We are currently delivering to an area of around 15 km from Bellandur. 
                    We will increase the area in the future. You can message on 9980300816 and enquire if your area is currently being serviced or not. 
                    In case you have made the payment and your area is not being serviced, we will refund the amount. 
                    If you are a group of people wanting to avail of the delivery, we can take that as a special request and service your locality based on mutual agreement.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How do I use the service provided by MyHangout.in?</h2>
                <h6>It’s actually very simple, 
                    Register with MyHangout.in to create your account by clicking on "Signup/Login” and filling up the form. 
                    Browse our catalogue and add the books to your reading list. 
                    Subscribe to a plan and request for delivery. 
                    You will get your delivery on your scheduled day. You can either pay online or choose COD. 
                    We’ll contact you for the COD.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What are the available plans?</h2>
                <h6>We have many plans which you can see on the subscription page when you register on the website. 
                    It is based on the number of books you want to receive at one time. 
                    We also have quarterly, half-yearly, and yearly payment choices. 
                    We give heavy discounts for subscribing to yearly plans.
</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How do I make the payment?</h2>
                <h6>You can make the payment through any one of the following methods: Credit card/Debit Card/ Cash / Cheque / UPI. If you want to make the payment through cash/cheque/draft, just select the Cash option. Our delivery executive will collect the payment. The cheque/draft should be made in the name of "Confidentliving Learning Private Limited".</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How do I find these titles?</h2>
                <h6>Search: The easiest way to find a title is to use the search option available on every page. 
                    There is a dropdown option on the left side of the search bar that allows you to change the type of search - title/author/genre. 
                    Serendipity: This feature will show a set of random titles each time you use the feature. 
                    New Arrivals: This feature shows the newest books that have been added to the catalogue. 
                    Genre: This feature shows you titles listed by genre and sub-genre.</h6>
                </div>     
            </Card>

            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What is "My Bookmarks"?</h2>
                <h6>This feature allows you to bookmark the books that you find interesting. 
                    To bookmark a book, hover on the image of the book cover and click the little bookmark image on the bottom left. 
                    To view your bookmarks, click on 'My Bookmarks' on the navigation menu. 
                    You may bookmark as many books as you want.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What is "My Queue"?</h2>
                <h6>Your queue is the list of books you want us to send to you. 
                    To add a book to the queue, hover on the image of the book cover and click the little plus icon towards the bottom right. 
                    To view your queue, use the 'My Queue' link in the navigation menu. 
                    Here, you can also re-order/remove the books in your queue. 
                    You may add as many books to your queue as you want.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What is "My History"?</h2>
                <h6>These are the books that you have borrowed so far.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What is My Account?</h2>
                <h6>My Account is the section from where you can view and manage details regarding your account. 
                    You can edit your profile, change your password, see billing details, and renew your membership.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What is the minimum number of books I need to maintain in My Queue?</h2>
                <h6>In order to have a high probability of delivery, we need you to maintain at least 12 books in the queue, at any point in time (but this also depends on your plan).</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How do I get books delivered after opening my account?</h2>
                <h6>After you have created your account, kindly add books to your queue. 
                    Once this is done, you have to click on the "Ask for delivery” and then on “Confirm delivery” button. 
                    The top available books from your booklist would be issued to you and delivery will be scheduled.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>After I finish reading, how do I get my next set of books?</h2>
                <h6>After you have finished reading, please ask for delivery (if you want the next book to be delivered). 
                    We would pick up the books that you have with you and deliver the next set of books from your booklist on the scheduled day.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Can I upgrade/downgrade my membership?</h2>
                <h6>Give us a call on 9980300816 and we will be able to help you.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How long can I keep the books with me?</h2>
                <h6>As long as you are making your subscription payments, you can keep the books with you. But we request you to return the books as early as possible so that someone else can read that book. In rare cases, we can recall the book. Post the expiry of membership we charge Rs 50 per book per week for the delay.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What if I damage/lose the book?</h2>
                <h6>If you lose/damage the book, you need to pay the MRP of the book to us, to continue the service.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Can I cancel my membership during my membership period?</h2>
                <h6>Yes, you can cancel your membership at any time during your membership period. 
                    This can be done by dropping an email to info@myhangout. in. 
                    Our customer care executive will call you to confirm your request and also note the reason for the closure. 
                    You will have to return any books that you have with you within 5 working days failing which an amount equal to the MRP of the books would be deducted from your security deposit. 
                    Any amount left after deducting the dues from your security deposit would be given to you. 
                    There would however be no refund of the membership fees or the registration fees.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What if the title I want is not available with you in your library?</h2>
                <h6>If any title is not available in our library, you can request the same by sending us an email. On a best effort basis, we will procure the book and add it to the collection. The procurement will vary depending on the popularity, availability, cost, reviews, etc.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Why didn't I receive the first book from my queue?</h2>
                <h6>Books are shipped in the order of your queue, subject to availability. If a particular title is not available, the next available book in your queue will be sent to you. We will fill book request as soon as the title of your choice is available.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How can I see my rental history?</h2>
                <h6>You can see your rental history by clicking on My History tab</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>How do I renew my Account?</h2>
                <h6>You can renew your account by going to My Subscription -- Buy Subscription.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Can I tempororily suspend Membership?</h2>
                <h6>The suspension facility is there only in annual accounts. The annual members can take a break of 7/14/30 days after informing and returning all the books. The same number of days are added to their accountsSend us an email at info@myhangout.in and we will help you.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Can I re-join once I have cancelled my membership?</h2>
                <h6>Yes you can – all you need to do is mail us at info@myhangout.in and we will call you back and get your account processed.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What are your delivery charges?</h2>
                <h6>Currently pickup and delivery is absolutely free. You will be given notice if there is any change and that will be applicable from next billing cycle.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What if I am not available at home when the delivery happens?</h2>
                <h6>If you are not available at home and miss a delivery, then we will try to deliver it again on the next delivery day scheduled for your area. (You should inform us if you’ve raised a delivery request and you need to step out on your scheduled day)</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>What if I miss raising the delivery request timely?</h2>
                <h6>You’ll receive the books on the next scheduled day.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info'>
                <h2>Do I need to return all the books at the same time or can I return one book only?</h2>
                <h6>We recommend that you return all the books.</h6>
                </div>     
            </Card>
            <Card style={{ width: '100%',height:'auto',border:'1px solid black',marginTop:'20px'}}>
                <div className='help-details-info' id='tc'>
                <h2>Terms and Conditions</h2>
                <p>Books are delivered once a week. If you are a 4-book member, you’ll get 4 books on your scheduled day. Delivery requests should be raised at least 24 hours in advance. You’ll receive a message with details of your delivery schedule once you subscribe. If the delivery request doesn’t come or is delayed, delivery isn’t possible. We pick up the old books and deliver the new ones on the same day. The delivery window is 11 a.m. to 6 p.m.
 
 Add lots of books to the queue. Put high-priority books on the top. If they’re available, they go. Do not add anything you don’t want to read. When you add books to the queue, make sure that most of the books are not already issued out. If you leave a special instruction to send a book on Dr. Seuss, 3-4 Dr. Seuss books should be there in the queue. If you have multiple readers in the family, let me know. I’ll guide you on how to split your queue to ensure you get books for everyone.
  
 You’ll receive the books in a waterproof taped packet. Open the delivery packet using scissors. Keep the packet safe. Return the books in the same packet. Do NOT return the books without a sealed packet. Use another packet if you have and tape it firmly before returning it. Dedicate a space/basket for the library books and the packet. It will help you find the books both to read and return.
  
 Keep a track of your schedule and keep the books ready for pickup. Leave the packet at the security gate if possible and pre-approve the entry. That way, you won’t have to be disturbed for the exchange. If the calls/doorbells go unanswered multiple times, we come back without exchanging the books.
  
 Renew your subscription a week in advance. The new cycle starts from the current date of expiration. This way, you neither lose money nor reading days. If the subscription has expired and the books are with you, a fine of INR 50 per book per week is applicable.
  
 The suspension facility is there only in annual accounts. The annual members can take a break of a maximum of 30 days per cycle after informing and returning all the books. The same number of days are added to their accounts.
  
 </p> 

 <p>
 Refunds are not applicable if the account has been inactive for more than 12 months or if there are dues.
 Damages will be fined. Take care of each book and:
 <br></br>
 1.   Do not eat while reading library books. It’s impossible to remove oil stains.
 <br></br>
 2.   Hold the books up in your hands while reading. Do NOT flat press them on beds/tables. Most books have glue binding these days. Any stretch at the spine causes pages to come out.
 <br></br>
 3.   Use bookmarks and not combs, credit cards, leaves, post-its, personal photos, etc. Do NOT dog-ear the books at all.
 <br></br>
 4.   Definitely, and most definitely, do not scribble on the books. I’ve received books back with your child’s Google Classroom’s password, the new cook’s phone number, gas booking number, etc. Not cool. Teach children not to scribble even if it’s an activity sheet. Make sure that little kids don’t have pencils/pens while reading. Scribbling is fined.
 <br></br>
 5.   Inform a day in advance if you wish to retain a book from the previous delivery. If you return less without informing in the special instructions, the next delivery will be skipped.
 <br></br>
 6.   When you pack the books for return, remember they are books. Stack them neatly. Do NOT stuff books in the packet like a laundry bag.
 <br></br>
 7.   Do not let children receive and return the packets themselves. Check the books once before giving them to kids. Inform me immediately if there’s any damage. Ask kids to wash their hands before reading. Avoid grime and food stains at all costs. Books can’t be washed, remember?
 </p>
                </div>     
            </Card>
        </div>
        )
    }
    export default Help;