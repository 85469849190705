import React from 'react'
import {Carousel} from 'react-bootstrap';
import './Carousel.css';
const CarouselContainer = () => {
    return (
      <>
        <div className = "Carousel-Container">
            <Carousel className = "carousel-container" variant = 'dark'>
      <Carousel.Item className ="carousel-item" interval={5000}>
      <div>
 
    <img
      className="d-block w-100"
      src='http://15.207.107.128:6200/images/image2.jpg'
      alt="First slide"
    />
  
  </div>
  
    <Carousel.Caption className = 'carousel-footer'>
      <div className = 'carousel-bottom-bar'>
        <div className = 'carousel-bottom-logo'>
        <img
          className="bottom-logo"
          src='http://139.59.3.2/blog/wp-content/uploads/2016/08/rsz_hangout_final400x142.png'
          alt="Bottom logo"
        />
        </div>
      <h3>My Hangout Library | your own Library</h3>
      <p>My Hangouts is a library of books. Select your books online and ask for a home delivery.</p>
      </div>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={5000}>
    <img
      className="d-block w-100"
      src="http://15.207.107.128:6200/images/image4.jpg"
      alt="Second slide"
    />
    <Carousel.Caption className = 'carousel-footer'>
    <div className = 'carousel-bottom-bar'>
        <div className = 'carousel-bottom-logo'>
        <img
          className="bottom-logo"
          src='http://139.59.3.2/blog/wp-content/uploads/2016/08/rsz_hangout_final400x142.png'
          alt="Bottom logo"
        />
        </div>
      <h3>My Hangout Library | your own Library</h3>
      <p>There are thousands of books in our collection for children – there’s always something for everyone.</p>
      </div>
      
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={5000}>
    <img
      className="d-block w-100"
      src="	http://15.207.107.128:6200/images/image5.jpg"
      alt="Third slide"
    />
    <Carousel.Caption className = 'carousel-footer'>
    <div className = 'carousel-bottom-bar'>
        <div className = 'carousel-bottom-logo'>
        <img
          className="bottom-logo"
          src='http://139.59.3.2/blog/wp-content/uploads/2016/08/rsz_hangout_final400x142.png'
          alt="Bottom logo"
        />
        </div>
      <h3>My Hangout Library | your own Library</h3>
      <p>My Hangouts is a library of books. Select your books online and ask for a home delivery.</p>
      </div>
    </Carousel.Caption>
  </Carousel.Item>
  <Carousel.Item interval={5000}>
    <img
      className="d-block w-100"
      src='http://15.207.107.128:6200/images/image8.jpg' alt="fourth slide"
    />
    <Carousel.Caption className = 'carousel-footer'>
    <div className = 'carousel-bottom-bar'>
        <div className = 'carousel-bottom-logo'>
        <img
          className="bottom-logo"
          src='http://139.59.3.2/blog/wp-content/uploads/2016/08/rsz_hangout_final400x142.png'
          alt="Bottom logo"
        />
        </div>
      <h3>My Hangout Library | your own Library</h3>
      <p>My Hangouts is a library of books. Select your books online and ask for a home delivery.</p>
      </div>
    </Carousel.Caption>
  </Carousel.Item>
  
</Carousel>
            
        </div>
        </>
    )
}

export default CarouselContainer;

