import React, { useState, useEffect, useContext,useRef } from 'react';
import './MySubscription.css';
import { Form, Row, Col, Card, Dropdown,Container, DropdownButton, Modal, ModalBody, ModalTitle } from 'react-bootstrap';
import { Grid, Paper } from '@material-ui/core';
import axios from 'axios';
import {Button} from '@material-ui/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { GlobalContext } from '../../contexts/GlobalContext';
import paytm from '../../images/paytm.png'
import phonepe from '../../images/phonepay.png'
import gpay from '../../images/gpay.png'
import PaymentHistoryList from './PaymentHistory';
import { Link } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Fade from '@mui/material/Fade';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


var sectionStyle = {
    width: "100%",
    height: "100vh",
};
var registration=0
var deposit=0
var renewal=0
var endDate=''
function MySubscription() {
    const [PURStatus, setPURStatus] = useState(false);
    const [UPDStatus, setUPDStatus] = useState(false);
    const showPUR = () => setPURStatus(!PURStatus);
    const showUPD = () => setUPDStatus(!UPDStatus);
    const { Url, user_id } = useContext(GlobalContext)
    const [plan, setPlan] = useState();
    const [planD, setPlanD] = useState();
    const [planDS, setPlanDS] = useState(false);
    const [PH, setPH] = useState(false);
    const [isCancel, setCancel] = useState(false);
    const [isSubmit, setCancelSubmit] = useState(false);
    const [PD, showPD] = useState(false);
    const [PDP, showPDP] = useState(false);
    const [supType, setSupType] = useState(0);
    const [show, setShow] = useState(false);
    const [showtc, setShowTC] = useState(false);
    const showTC = () => setShowTC(!showtc);
    const [showPayment, setShowPayment] = useState(false);
    const [PDdata, setPDData] = useState({
        subs_id: "",
        amt_type: ""
    });
    const [CDdata, setCDData] = useState({
        acc_no: "",
        iifc: "",
        upi_no: ""
    });
    const [value, setValue] = useState('Click here!!');
    const [isDisable, disableButton] = useState(false);
    let btnRef = useRef();
    const [PDdetails, setPDDetails] = useState();
    const handleCancel = () => {
        setCancel(true);
        setCancelSubmit(true);
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCancelClose = () => {
        setShow(false);
        setCancel(false);
        setCancelSubmit(false);
    }
    const handleShowPayment = () => setShowPayment(true);
    const handleClosePayment = () => setShowPayment(false);
    function requestPaymentDetails() {
        axios.post(`${Url}/Library/selectSubscriptionPlan`, {
            "user_id": localStorage.getItem('user'),
            "subs_id": PDdata.subs_id,
            "amt_type": PDdata.amt_type
        })
            .then(res => {
                console.log(res.data);
                setPDDetails(res.data.subscription_data);
                showPDP(true);
                deposit= res.data.subscription_data.deposit_amt;
                registration = res.data.subscription_data.registration_fee;
                renewal = res.data.subscription_data.duration_cost;
                endDate = res.data.end_date;
                handleShowPayment()
            });
    };
    function requestUpdatedPaymentDetails() {
        axios.post(`${Url}/Library/UpdateSubscription`, {
            "user_id": localStorage.getItem('user'),
            "subs_id": PDdata.subs_id,
            "amt_type": PDdata.amt_type
        })
            .then(res => {
                console.log(res.data);
                setPDDetails(res.data.subscription_data);
                showPDP(true);
                handleShowPayment()
            });
    };

    function confirmPaymentDetails(e, total_cost) {
        setValue(("Done, Please wait for confirmation..."));
        disableButton(true)
        if(btnRef.current){
            btnRef.current.setAttribute("disabled", "disabled");
          }
        console.log("Sending payment")
        axios.post(`${Url}/Library/addPayment`, {
            "user_id": localStorage.getItem('user'),
            "payment_type": "Cash",
            "payment_mode": "ONLINE",
            "deposit":deposit,
            "registration":registration,
            "renewal":renewal,
            "amount_paid": total_cost,
            "transaction_id": "",
            "end_date":endDate
        })
            .then(res => {
                console.log(res.data);
                alert(res.data.Message);
                handleClosePayment();
            });
    };
    function cancelSubscription() {
        axios.post(`${Url}/Library/cancelSubscription`, {
            "user_id": localStorage.getItem('user'),
            "plan_id": 2,
            "acc_no": CDdata.acc_no,
            "ifsc_code": CDdata.iifc,
            "upi_id": CDdata.upi_no
        })
            .then(res => {
                console.log(res.data);
                alert(res.data.Message);
                handleCancelClose();
            });
    };

    function handlePD(e) {
        const newdata = { ...PDdata }
        newdata.amt_type = e.target.value
        setPDData(newdata)
        console.log(newdata)
    }
    function handleCD(e) {
        const newdata1 = { ...CDdata }
        newdata1[e.target.id] = e.target.value
        setCDData(newdata1)
        console.log(newdata1)
    }

    function resetRadioButtons() {
        //document.getElementById("formHorizontalRadios1").checked = false;
        document.getElementById("formHorizontalRadios2").checked = false;
        document.getElementById("formHorizontalRadios3").checked = false;
        document.getElementById("formHorizontalRadios4").checked = false;
    }

    useEffect(() => {

        /*const script = document.createElement('script');

        script.src = "https://checkout.razorpay.com/v1/payment-button.js";
        script.async = true;
        
        document.body.appendChild(script);*/



        async function getPlanData() {
            await axios.get(`${Url}/Library/SubscriptionType`)
                .then(res => {
                    console.log(res.data.subscription_types);
                    setPlan(res.data.subscription_types);
                });
        }
        async function getSubscriptionDetails() {
            await axios.post(`${Url}/Library/SubscriptionDetails`,
                {
                    "user_id": localStorage.getItem('user')
                }
            )
                .then(res => {
                    console.log(res.data.subscription_details);
                    setPlanD(res.data.subscription_details);
                    setPlanDS(true);

                });
        }
        getPlanData();
        getSubscriptionDetails();

    }, []);


    const paperStyle = { padding: 20, height: 'auto', width: '100%', margin: 'auto' }
    return (
        <section style={sectionStyle}>
            <Container fluid>
                <Row className='mysub-row'>
                    <div className='mya-area'>

                        <div className='mya-card-area'>
                            <Grid>
                                <Paper elevation={10} style={paperStyle} className='mya-paper'>
                                    <div className='mya-container'>
                                        <h4>My Subscription</h4>
                                        <Row>
                                            <Col>
                                                {planDS === true ? <Card style={{ width: 'auto', height: 'auto', border: '1px solid black', marginTop: '20px' }}>
                                                    <div className='mysub-details-info'>
                                                        <h4 style={{ marginBottom: '20px' }}>Plan Details</h4>
                                                        <h6>Plan Name: {planD.plan_name}</h6>
                                                        <h6>Start Date: {planD.start_date}</h6>
                                                        <h6>Expiry Date: {planD.expiry_date}</h6>
                                                        <h6>Subscription Status: {planD.subscription_status}</h6>
                                                        <Button variant={"contained"} color={'primary'} style={{ margin: '1% 1%' }} onClick={() => {
                                                            setShowTC(false);
                                                            setUPDStatus(false);
                                                            showPUR()
                                                        }}>Purchase Subscription</Button>
                                                    {/*  <Button variant={"outlined"} style={{ margin: '1% 1%',backgroundColor:'yellow'}} onClick={() => {
                                                            setShowTC(false);
                                                            setPURStatus(false);
                                                            showUPD()
                                                        }}>Update Subscription</Button>
                                                        <Button variant={"outlined" } style={{ margin: '1% 1%',backgroundColor:'red'}} onClick={() => {
                                                            setShowTC(false);
                                                            setPURStatus(false);
                                                            setUPDStatus(false);
                                                            handleShow()
                                                        }}>Cancel Subscription</Button>*/}
                                                        <Link to={""} className='signup-click' onClick={() => {
                                                            showTC();
                                                        }}>Terms and Condition</Link>
                                                        {showtc === true ?
                                                            <Card style={{ width: 'auto', height: 'auto', border: '1px solid black', marginTop: '20px' }}>
                                                                <div className='help-details-info' id='tc'>
                                                                    <h2>Terms and Conditions</h2>
                                                                    <p>Books are delivered once a week. If you are a 4-book member, you’ll get 4 books on your scheduled day. Delivery requests should be raised at least 24 hours in advance. You’ll receive a message with details of your delivery schedule once you subscribe. If the delivery request doesn’t come or is delayed, delivery isn’t possible. We pick up the old books and deliver the new ones on the same day. The delivery window is 11 a.m. to 6 p.m.

                                                                        Add lots of books to the queue. Put high-priority books on the top. If they’re available, they go. Do not add anything you don’t want to read. When you add books to the queue, make sure that most of the books are not already issued out. If you leave a special instruction to send a book on Dr. Seuss, 3-4 Dr. Seuss books should be there in the queue. If you have multiple readers in the family, let me know. I’ll guide you on how to split your queue to ensure you get books for everyone.

                                                                        You’ll receive the books in a waterproof taped packet. Open the delivery packet using scissors. Keep the packet safe. Return the books in the same packet. Do NOT return the books without a sealed packet. Use another packet if you have and tape it firmly before returning it. Dedicate a space/basket for the library books and the packet. It will help you find the books both to read and return.

                                                                        Keep a track of your schedule and keep the books ready for pickup. Leave the packet at the security gate if possible and pre-approve the entry. That way, you won’t have to be disturbed for the exchange. If the calls/doorbells go unanswered multiple times, we come back without exchanging the books.

                                                                        Renew your subscription a week in advance. The new cycle starts from the current date of expiration. This way, you neither lose money nor reading days. If the subscription has expired and the books are with you, a fine of INR 50 per book per week is applicable.

                                                                        The suspension facility is there only in annual accounts. The annual members can take a break of a maximum of 30 days per cycle after informing and returning all the books. The same number of days are added to their accounts.

                                                                    </p>

                                                                    <p>
                                                                        Refunds are not applicable if the account has been inactive for more than 12 months or if there are dues.
                                                                        Damages will be fined. Take care of each book and:
                                                                        <br></br>
                                                                        1.   Do not eat while reading library books. It’s impossible to remove oil stains.
                                                                        <br></br>
                                                                        2.   Hold the books up in your hands while reading. Do NOT flat press them on beds/tables. Most books have glue binding these days. Any stretch at the spine causes pages to come out.
                                                                        <br></br>
                                                                        3.   Use bookmarks and not combs, credit cards, leaves, post-its, personal photos, etc. Do NOT dog-ear the books at all.
                                                                        <br></br>
                                                                        4.   Definitely, and most definitely, do not scribble on the books. I’ve received books back with your child’s Google Classroom’s password, the new cook’s phone number, gas booking number, etc. Not cool. Teach children not to scribble even if it’s an activity sheet. Make sure that little kids don’t have pencils/pens while reading. Scribbling is fined.
                                                                        <br></br>
                                                                        5.   Inform a day in advance if you wish to retain a book from the previous delivery. If you return less without informing in the special instructions, the next delivery will be skipped.
                                                                        <br></br>
                                                                        6.   When you pack the books for return, remember they are books. Stack them neatly. Do NOT stuff books in the packet like a laundry bag.
                                                                        <br></br>
                                                                        7.   Do not let children receive and return the packets themselves. Check the books once before giving them to kids. Inform me immediately if there’s any damage. Ask kids to wash their hands before reading. Avoid grime and food stains at all costs. Books can’t be washed, remember?
                                                                    </p>
                                                                </div>
                                                            </Card> : <div></div>}
                                                        {PURStatus === true || UPDStatus === true ?
                                                            <Card style={{
                                                                width: 'auto', height: 'auto', border: '2px solid white',
                                                                marginTop: '10px', background: '#232c32', borderRadius: '10px'
                                                            }}>
                                                                <div className='mysub-details-info'>
                                                                    {PURStatus === true ?
                                                                        <h4 style={{ color: 'white', margin: '1% 1%' }}>Select Your Plan</h4>
                                                                        : <h4 style={{ color: 'white', margin: '1% 1%' }}>Update Your Plan</h4>}


                                
                                                                    <DropdownButton title="Choose Plan">
                                                                        <Dropdown.Item as="button" onClick={() => {
                                                                            showPD(false);
                                                                            setSupType(0);
                                                                            setPDData({ subs_id: 1 })
                                                                            showPD(true);
                                                                            // window.setTimeout(resetRadioButtons(), 1000);
                                                                        }}>HABIT</Dropdown.Item>
                                                                        <Dropdown.Item as="button" onClick={() => {
                                                                            showPD(false);
                                                                            setSupType(1);
                                                                            setPDData({ subs_id: 2 })
                                                                            showPD(true);
                                                                            // window.setTimeout(resetRadioButtons(), 1000);
                                                                        }}>HUNGRY</Dropdown.Item>
                                                                        <Dropdown.Item as="button" onClick={() => {
                                                                            showPD(false);
                                                                            setSupType(2);
                                                                            setPDData({ subs_id: 3 })
                                                                            showPD(true);
                                                                            //  window.setTimeout(resetRadioButtons(), 1000);
                                                                        }}>MONSTER</Dropdown.Item>
                                                                    </DropdownButton>
                                                                    {PD === true ? <Card style={{
                                                                        width: 'auto', height: 'auto', border: '2px solid white',
                                                                        marginTop: '10px', background: 'white', borderRadius: '2px'
                                                                    }}>
                                                                        <h5 style={{ color: 'black', margin: '1% 1%', fontWeight: 'bold' }}>Plan Description</h5>
                                                                        <h6 style={{ color: 'black', margin: '1% 2%' }}>Plan Name: {plan[supType].subscription_plan_type}</h6>
                                                                        <h6 style={{ color: 'black', margin: '1% 2%' }}>One Time Registration Fee: Rs {plan[supType].registration_fee}</h6>
                                                                        <h6 style={{ color: 'black', margin: '1% 2%' }}>Refundable Deposit: Rs {plan[supType].deposit_to_be_done}</h6>
                                                                        <h6 style={{ color: 'black', margin: '1% 2%' }}>No. of Books Allowed: {plan[supType].subscription_book_counter}</h6>
                                                                        <fieldset>
                                                                            <Form.Group as={Row} className="mb-3">
                                                                                <Form.Label as="legend" column lm={2} style={{ color: 'black', margin: '1% 2%', fontWeight: 'bold' }}>
                                                                                    Select Subscription Fees:
                                                                                </Form.Label>
                                                                                <Col sm={10}>

                                                                                    <Form.Check
                                                                                        type="radio"
                                                                                        label={"Quarterly: " + plan[supType].quaterly_amt}
                                                                                        name="formHorizontalRadios"
                                                                                        id="formHorizontalRadios2"
                                                                                        style={{ color: 'black', margin: '1% 3%' }}
                                                                                        value="quaterly_amount"
                                                                                        // onChange = {setPDData({amt_type:"quaterly_amount"})}
                                                                                        onChange={(e) => handlePD(e)}
                                                                                    />
                                                                                    <Form.Check
                                                                                        type="radio"
                                                                                        label={"Half yearly: " + plan[supType].half_yearly}
                                                                                        name="formHorizontalRadios"
                                                                                        id="formHorizontalRadios3"
                                                                                        style={{ color: 'black', margin: '1% 3%' }}
                                                                                        value="half_yearly"
                                                                                        // onChange = {setPDData({amt_type:"half_yearly"})}
                                                                                        onChange={(e) => handlePD(e)}
                                                                                    />
                                                                                    <Form.Check
                                                                                        type="radio"
                                                                                        label={"Annual: " + plan[supType].annually_amt}
                                                                                        name="formHorizontalRadios"
                                                                                        id="formHorizontalRadios4"
                                                                                        style={{ color: 'black', margin: '1% 3%' }}
                                                                                        value="annualy"
                                                                                        // onChange = {setPDData({amt_type:"annualy"})}
                                                                                        onChange={(e) => handlePD(e)}
                                                                                    />
                                                                                </Col>
                                                                            </Form.Group>
                                                                        </fieldset>
                                                                        {PURStatus === true ?
                                                                            <Button variant="contained"  color='primary' style={{ marginLeft: '5rem', marginRight: '5rem' }} onClick={requestPaymentDetails}>Proceed to Payment</Button>
                                                                            : <Button variant="contained"  color='primary' style={{ marginLeft: '5rem', marginRight: '5rem' }} onClick={requestUpdatedPaymentDetails}>Proceed to Payment</Button>}
                                                                    </Card> : <div></div>}

                                                                </div>
                                                            </Card> : <div></div>}
                                                    </div>
                                                </Card> : <div></div>}
                                            </Col>
                                            <Col>
                                                <PaymentHistoryList userID={user_id} url={Url} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Paper>
                            </Grid>
                        </div>

                        {/* </div> */}
                        <Modal show={show} onHide={handleCancelClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Cancel Subscription</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Are you sure you want to cancel the subscription ?
                                {isCancel === true ? <div>
                                    <br></br>
                                    <Form>
                                        <Form.Group as={Col} controlId="formGridAcc">
                                            <Form.Label style={{ fontWeight: 'bold' }}>Account Number:</Form.Label>
                                            <Form.Control type="number" placeholder="Enter Account Number"
                                                onChange={(e) => handleCD(e)}
                                            />
                                        </Form.Group>
                                        <br></br>
                                        <Form.Group as={Col} controlId="formGridiifc">
                                            <Form.Label style={{ fontWeight: 'bold' }}>IIFC Code:</Form.Label>
                                            <Form.Control type="text" placeholder="Enter IIFC Code"
                                                onChange={(e) => handleCD(e)}
                                            />
                                        </Form.Group>
                                        <br></br>
                                        <Form.Group as={Col} controlId="formGridupi">
                                            <Form.Label style={{ fontWeight: 'bold' }}>UPI ID:</Form.Label>
                                            <Form.Control type="text" placeholder="Enter UPI ID"
                                                onChange={(e) => handleCD(e)}
                                            />
                                        </Form.Group>
                                    </Form>
                                </div> : <div></div>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCancelClose}>
                                    No
                                </Button>
                                {isSubmit === false ? <Button variant="primary" onClick={handleCancel}>
                                    Yes
                                </Button> : <Button variant="primary" onClick={cancelSubscription}>
                                    Cancel Subscription
                                </Button>
                                }
                            </Modal.Footer>
                        </Modal>
                        {PDP === true ? <Modal show={showPayment} onHide={handleClosePayment}>
                            <Modal.Header closeButton>
                                <Modal.Title>Payment Details</Modal.Title>
                            
                            </Modal.Header>
                            <Modal.Body>
                                <h5 style={{ fontWeight: 'bold' }}>Order Summary:</h5>
                                <h6>One Time Registration Fee: Rs {PDdetails.registration_fee}</h6>
                                <h6>Deposit Amount: Rs {PDdetails.deposit_amt}</h6>
                                <h6>Subscription Cost: Rs {PDdetails.duration_cost}</h6>
                                <br></br>
                                <h6 style={{ fontWeight: 'bold' }}>Total to be Paid: Rs {PDdetails.total_cost}</h6>
                                <br></br>
                            {/* <h6 style={{ fontWeight: 'bold' }}>Pay using Bank Details</h6>
                                <br></br>
                                <h6>Bank Name: {PDdetails.Bank}</h6>
                                <h6>Account Name: {PDdetails.acct_name}</h6>
                                <h6>Account Number: {PDdetails.account_no}</h6>
                                <h6>IIFC Code: {PDdetails.IFSC_CODE}</h6>
                            <br></br>*/}
                                <h6 style={{ fontWeight: 'bold' }}>or Scan the QR code</h6>
                                <h6>On Payment Completion</h6>
                                { isDisable ===false?
                                <Button ref={btnRef} variant="contained"  color='primary' onClick={
                                    (e) => confirmPaymentDetails(e, PDdetails.total_cost)}>
                                    {value}
                                </Button>:"Done, Please wait for confirmation..."}
                                <Row>
                                    <Col>
                                        <img src={paytm} style={{ width: '100%', height: '10vh' }}></img>
                                    </Col>
                                    <Col>
                                        <img src={phonepe} style={{ width: '100%', height: '10vh' }}></img>
                                    </Col>
                                    <Col>
                                        <img src={gpay} style={{ width: '100%', height: '10vh' }}></img>
                                    </Col>
                                </Row>

                                <img src={PDdetails.qr_code_image} style={{ width: '100%', height: 'auto' }}></img>

                                <div>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                            { /* <h6>On Payment Completion</h6>
                                <Button variant="contained"  color='primary' onClick={
                                    (e) => confirmPaymentDetails(e, PDdetails.total_cost)}>
                                    Click here !!
                                </Button>*/}
                            </Modal.Footer>
                        </Modal> : <div></div>}
                        <ToastContainer />
                    </div>
            </Row>
            </Container>
        </section>
    )
}
export default MySubscription;