import React from 'react';
import './Service.css';
function Services() {
    return (
        <>
        <div className = 'services-container' id='services'>
            <div className = 'services-title'>
                <h2 className = 'title1'>SERVICES.</h2>
            </div>
            <div className = 'vertical-line-divider'></div>
            <hr className = 'service-hz-line'></hr>
            <div className = 'service-details'>
                <p className = 'service-details-p'>
                    My Hangout is a library, based out of Bellandur, Bangalore. 
                    We operate on home-delivery mode.
                </p>
            </div>
        </div>
        
        </>
    )
}

export default Services;
