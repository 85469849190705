import React,{useState,useEffect,useContext}from 'react';
import  './MyAccount.css';
import {Form,Row,Col,FloatingLabel, Container} from 'react-bootstrap';
import {Grid,Paper,Button} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactRoundedImage from "react-rounded-image";
import { GlobalContext } from '../../contexts/GlobalContext';
var sectionStyle = {
    width: "100%",
    height: "100vh",
  };
 
function MyAccount(){
    const { Url,user_id } = useContext(GlobalContext);
    const url = `${Url}/Library/userUpdate`
    const [data,setData] = useState({
        first_name:"",
        last_name:"",
        password:"",
        email:"",
        dob:"",
        primary_mobile:"",
        alternate_mobile:0,
        gender:"",
        main_address:"",
        landmark:"",
        pincode: "",
        city:"",
        state:""
    })
    useEffect(async() => {
        await axios.post(`${Url}/Library/userAccountDetails`,{
            user_id:user_id
        })
          .then(res =>{
            console.log(res.data);
            setData({
                first_name:res.data.user_details.full_name,
                password:res.data.user_details.password,
                    email: res.data.user_details.email,
                    dob: res.data.user_details.date_of_birth,
                    primary_mobile: res.data.user_details.mobile_no,
                    gender: res.data.user_details.gender,
                    main_address: res.data.user_details.address,
                    landmark: "",
                    pincode: res.data.user_details.pincode,
                    city: res.data.user_details.city,
                    state: res.data.user_details.state
            });
        });
      }, []);

    function submit(e){
        e.preventDefault();
        console.log(data.first_name);
        console.log(data.email);
        console.log(data.dob);
        console.log(data.primary_mobile);
        console.log(data.gender);
        console.log(data.main_address);
        console.log(data.pincode);
        console.log(data.city);
        console.log(data.state);
       /* if((data.first_name  && data.password && data.email && data.dob && 
            data.primary_mobile && data.gender && data.main_address && data.gender && 
            data.landmark && data.pincode && data.city && data.state) === ""){*/
        if((data.first_name && data.email && data.dob && 
                    data.primary_mobile && data.gender && data.main_address &&  
                    data.pincode && data.city && data.state && data.password) === ''){
                    toast("All fields are Mandatory,Please fill the details");
            }else{
                axios.post(url,{
                    full_name: data.first_name,
                   // password: data.password,
                    password: data.password,
                    email: data.email,
                    dob: data.dob,
                    primary_mobile: data.primary_mobile,
                    alternate_mobile:0,
                    gender: data.gender,
                    main_address: data.main_address,
                   // landmark: data.landmark,
                    landmark: "",
                    pincode: data.pincode,
                    city: data.city,
                    state: data.state
                })
                .then(res =>{
                    console.log(res)
                    if(res.data.Code === 200){
                toast("Account Details Updated Successfully");
            }else{
                toast(res.data.Message);
            }
                })
            }
        
    }

    function handle(e){
        const newdata = {...data}
        newdata[e.target.id] = e.target.value
        setData(newdata)
        console.log(newdata)
    }    

    let navigate = useNavigate();
    const paperStyle = {padding: 20, height: 'auto', width: '100%', margin:'auto'}
    return(
        <section style={sectionStyle}>
            <Container fluid>
            <div className='myaccount-row'>
            <div className='mya-area'>
                    <div className='mya-card-area'>      
                    <Grid>
                    <Paper elevation={10} style = {paperStyle} className = 'mya-paper'>
                        <div className='mya-container'>
                            <h4 className='title-mya'>My Account Details</h4> 
                            <Row>
                                <Col xs={6}>
                                <div className='account-img' style={{ display: "flex"}}>
                                <ReactRoundedImage imageWidth="150" imageHeight="150" image="https://www.freeiconspng.com/uploads/person-icon-8.png" /></div>
                                </Col>
                                <Col xs={6}>
                                <h4 className='mt-5'>{data.first_name}</h4>
                                </Col>
                            </Row>
                            <div className='mya-textboxes'>
                            <Row>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="first_name"
                                    type="name"
                                    placeholder="name"
                                    onChange={(e)=>handle(e)}
                                    value={data.first_name}
                                    />
                                    <label htmlFor="first_name">First Name</label>
                                    </Form.Floating>
                                    </Col>
                                    <Col>
                            <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="password"
                                    type="text"
                                    placeholder="password"
                                    onChange={(e)=>handle(e)}
                                    value={data.password}
                                    />
                                    <label htmlFor="password">Password</label>
                                    
                            </Form.Floating>
                            </Col>
                            </Row>
                            <Row>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="dob"
                                    type="text"
                                    placeholder="Date of Birth"
                                    onChange={(e)=>handle(e)}
                                    value={data.dob}
                                    />
                                    <label htmlFor="dob">Date of Birth(DD-MM-YYYY)</label>
                                    </Form.Floating>
                                </Col>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="primary_mobile"
                                    type="mobile"
                                    placeholder="Mobile No."
                                    onChange={(e)=>handle(e)}
                                    value={data.primary_mobile}
                                    />
                                    <label htmlFor="primary_mobile">Mobile No.</label>
                                    </Form.Floating>
                                </Col>
                                <Col md>
                                    <FloatingLabel className = 'textbox-styling-3-mya' controlId="gender" label="Gender">
                                    <Form.Select aria-label="Floating label select example"
                                    onChange={(e)=>handle(e)}
                                    value={data.gender}>
                                        <option>{data.gender}</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                                    <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="main_address"
                                    type="address"
                                    placeholder="Address"
                                    onChange={(e)=>handle(e)}
                                    value={data.main_address}
                                    />
                                    <label htmlFor="main_address">Address</label>
                                    </Form.Floating>

                            <Row>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="state"
                                    type="state"
                                    placeholder="state"
                                    onChange={(e)=>handle(e)}
                                    value={data.state}
                                    />
                                    <label htmlFor="state">State</label>
                                    </Form.Floating>
                                </Col>
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="city"
                                    type="city"
                                    placeholder="city"
                                    onChange={(e)=>handle(e)}
                                    value={data.city}
                                    />
                                    <label htmlFor="city">City</label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                            <Row>
                                
                                <Col>
                                <Form.Floating>
                                    <Form.Control className='textbox-styling-mya'
                                    id="pincode"
                                    type="pincode"
                                    placeholder="pincode"
                                    onChange={(e)=>handle(e)}
                                    value={data.pincode}
                                    />
                                    <label htmlFor="pincode">Pincode</label>
                                    </Form.Floating>
                                </Col>
                            </Row>
                             
                                    

                                      <div className='mya-btn-area'>
                                            <Button variant='contained' size='medium' className='mya-btn' id = 'mya-btn' onClick={(e) => submit(e)}>
                                            <h5 className='mya-btn-title'>Update</h5> </Button>
                                        </div>                
                            </div>   
                                
                        </div>
                    </Paper>
                    </Grid>
                </div>
                
                {/* </div> */}
                <ToastContainer />
            </div>
            </div>
            </Container>
        </section>
    )
}
export default MyAccount;