import React from 'react';
import './ContactUs.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Form,Button,Row,Col,FloatingLabel} from 'react-bootstrap';
import {faFacebook,faGooglePlusG,faTwitter,faYoutube} from '@fortawesome/free-brands-svg-icons';

function ContactUs() {
    const fbClick = () => {
        window.open("https://www.facebook.com/libraryhangout/");
      };
      const extClick = () => {
        window.open("https://www.confidentliving.co.in");
      };
      const ytClick = () => {
        window.open("https://www.youtube.com/channel/UCD6b7gFjETlFR451gCl_jlg");
      };
      const mailClick = () => {
        window.open("mailto:aanchal.agrawal@confidentliving.co.in");
      };
    return (
        <div className='contactUs-conatainer' id='contact'>
            <div className='form-container'>
            <Form>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridName">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter Name" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                    </Form.Group>
                </Row>

                <FloatingLabel controlId="floatingTextarea2" label="Message">
                    <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: '25vh', width: "100%" }}
                    />
                </FloatingLabel>

                <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check type="checkbox" label="Send a copy of this email to yourself" />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
            </div>

            <div className='socialmedia-container'>
                <h3 className='contactus-title'>CONTACT US</h3>
                <div className='contactus-icons-container'>
                        <ul class = 'contactus-icons'>
                            
                            <li onClick={fbClick}><FontAwesomeIcon icon={faFacebook} size='2x'></FontAwesomeIcon></li>
                            <li><FontAwesomeIcon icon={faTwitter} size='2x'></FontAwesomeIcon></li>
                            <li onClick={mailClick}><FontAwesomeIcon icon={faGooglePlusG} size='2x'></FontAwesomeIcon></li> 
                            <li onClick={ytClick}><FontAwesomeIcon icon={faYoutube} size='2x'></FontAwesomeIcon></li>
                            
                        </ul>
                </div>
                <div className='contact-us-details'>
                    <h3>Hangout Library, Green Glen Layout,</h3>
                    <h3>Bellandur, Bangalore,  560103</h3>
                    <h3>info@myhangout.in</h3>
                    <h3>9980300816</h3>
                    <h3>www.myhangout.in</h3>
                    <h3>If you want to work with us send your resume at info@myhangout.in</h3>
                </div>
            </div>
        </div>
        )
    }
    
    export default ContactUs;