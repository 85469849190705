import React from "react";
import axios from "axios";
import {Form,Row,Col,Card} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class PaymentHistoryList extends React.Component{
    state = {
        payHis:[]
    };
    
    async componentDidMount(){
        try{
            await axios.post(`${this.props.url}/Library/paymentHistories`,{
                user_id:localStorage.getItem('user')
            }).then(
                res =>{
                    console.log(res.data);
                    this.setState({payHis: res.data.payment_history});
                });
            
                 }catch(error){
                    console.log(error);      
                 }
    }
    render(){
        return(
            
            <div>
                <h4>Payment History</h4> 
                <Col>
                <Card style={{ width: 'auto',height:'auto',border:'1px solid black',marginTop:'20px'}}>
        {this.state.payHis.map(pH => {
            return (
            
                                <div className='mysub-details-info'>
                                
                                {pH.payment_status === 'UNVERIFIED' ?<Card key={pH.payment_id} style={{ width: 'auto',height:'auto',border:'2px solid red',marginTop:'10px'}}>
                                <div className='mysub-details-info'>
                                <h6>Payment ID: {pH.transaction_id}</h6>
                                <h6>Payment Date: {pH.payment_date}</h6>
                                <h6>Amount Paid: Rs {pH.amount_paid}</h6>
                                {pH.payment_status === 'UNVERIFIED' 
                                    ?<h6 style={{color:'red'}}>Payment Status: {pH.payment_status}</h6>
                                
                                    :<h6 style={{color:'green'}}>Payment Status: {pH.payment_status}</h6>}        
                                </div>        
                                </Card> :<Card key={pH.payment_id} style={{ width: 'auto',height:'auto',border:'2px solid red',marginTop:'10px'}}>
                                <div className='mysub-details-info'>
                                <h6>Payment ID: {pH.transaction_id}</h6>
                                <h6>Payment Date: {pH.payment_date}</h6>
                                <h6>Amount Paid: Rs {pH.amount_paid}</h6>
                                {pH.payment_status === 'UNVERIFIED' 
                                    ?<h6 style={{color:'red'}}>Payment Status: {pH.payment_status}</h6>
                                
                                    :<h6 style={{color:'green'}}>Payment Status: {pH.payment_status}</h6>}        
                                </div>        
                                </Card>}
                            </div>        
                            
            );
        })}
        </Card> 
        </Col>
            
                            <ToastContainer />
                            </div>
                    
        )
    }


}