import React from 'react';
import 'react-bootstrap';
import mission from '../../images/mission.png';
import intro from '../../images/who are we.png';
import collection from '../../images/collection.jpg';
import './Cards.css';

function Cards() {
    return (
    <div className = 'card-container'>
        <div className = 'container mt-3'>
            <div className = 'row'>
                <div className = 'col-md-4'>
                    <div className = 'card shadow-lg'>
                    <img src = {intro} alt = 'Mission'/>
                    <div className = 'card-body-service'>
                        <h3 className = 'card-title'>WHO WE ARE</h3>
                        <p className = 'card-details'>
                        My Hangout is a community library of books based in Bangalore. 
                        We do free home deliveries in and around Bellandur (15 km radius) You can browse through our entire collection online. 
                        We use the very powerful library services software. 
                        You can choose the books you want, add them to your queue and ask for delivery – we’ll deliver them so you won’t have to deal with traffic. 
                        We deliver once a week.
                        </p>
                    </div>
                    </div>    
                </div>
                <div className = 'col-md-4'>
                    <div className = 'card shadow-lg'>
                    <img src = {mission} alt = 'Mission'/>
                    <div className = 'card-body-service'>
                        <h3 className = 'card-title'>OUR MISSION</h3>
                        <p className = 'card-details'>
                        Everyone at Hangout Library believes in the importance of books in our lives and how they can shape and influence us. 
                        All of us wish to promote book reading habits across all age-groups and more importantly in the next-gen children. 
                        We want to be the reading face of the new generation.
                        </p>
                    </div>
                    </div>    
                </div>
                <div className = 'col-md-4'>
                    <div className = 'card shadow-lg'>
                    <img src = {collection} alt = 'Mission'/>
                    <div className = 'card-body-service'>
                        <h3 className = 'card-title'>OUR COLLECTION</h3>
                        <p className = 'card-details'>
                        We have a very good collection of more than 15000 books. 
                        And we are always on the prowl, adding books every month. 
                        We add all the popular, best-seller, and critically acclaimed books immediately on release. 
                        Besides, we also add books on demand in case they are not in our stock. 
                        The collection of books at our library is something we are extremely proud of. 
                        The entire collection is handpicked with a lot of time and devotion going into picking the right books.
                        </p>
                    </div>
                    </div>    
                </div>
            </div>
        </div>
    </div>
    )
}

export default Cards;
