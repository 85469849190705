import React from "react";
import axios from "axios";
import {Form,Row,Col,Card, Container} from 'react-bootstrap';
import './BookList.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPlusCircle,faBookmark,faMinus} from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import qs from 'qs';
import { GlobalContext } from '../../contexts/GlobalContext';

export default class BookmarkList extends React.Component{
    state = {
        bookmarks:[]
    };
    
    async componentDidMount(){
        try{
            await axios.post(`${this.props.url}/Library/getBookmarkedList`,{
                user_id:localStorage.getItem('user')
            }).then(
                res =>{
                    console.log(res.data);
                    this.setState({bookmarks: res.data.bookmarked_list});
                });
            
                 }catch(error){
                    console.log(error);      
                 }
    }

     addToQueue = (e,book_title,catalouge_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(book_title);
        console.log(catalouge_id)
        axios.post(`${this.props.url}/Admin/addBooksToQueue`,{
            user_id:localStorage.getItem('user'),
            book_name:book_title,
            catalogue_id:catalouge_id
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Code)
            if(res.data.Code === 200){
                toast(res.data.Message);
                this.componentDidMount()
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    removeFromBookmark = (e,bookmarked_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(bookmarked_id);
        axios.post(`${this.props.url}/Library/deleteBooksFromBookmarked`,{
             user_id:localStorage.getItem('user'),
            bookmarked_id:bookmarked_id,
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Result)
            if(res.data.Result === 'Success'){
                this.componentDidMount()
                toast(res.data.Message); 
                    
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    removeFromQueue = (e,book_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(book_id);
        axios.post(`${this.props.url}/Library/removeBooksFromQueue`,{
             user_id:localStorage.getItem('user'),
            book_id:book_id,
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Result)
            if(res.data.Result === 'Success'){
                this.componentDidMount()
                toast(res.data.Message); 
                    
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    addToBookmark = (e,catalouge_id) => {
        e.preventDefault();
        console.log(this.props.userID)
        console.log(catalouge_id)
        axios.post(`${this.props.url}/addBooksToBookmark`,{
            user_id:localStorage.getItem('user'),
            catalogue_id:catalouge_id
        })
        .then(res =>{
            console.log(res.data)
            console.log(res.data.Code)
            if(res.data.Code === 200){
                toast(res.data.Message);
                this.componentDidMount()
            }else{
                toast(res.data.Message);
            }
            
        })
        
    }
    render(){
        return(
            
            <div>
                <Container>
                        <Row className="lib-row">
                            <h2>My Bookmarks</h2> 
                            {this.state.bookmarks.map((bookmark) => {
                            return(
                                <Col xs={4} sm={3} lg={2}  key = {bookmark.bookmarked_id}>
                                <Card className="books-cards">
                                    <Card.Img variant="top" src={bookmark.book_image} className="books-image" />
                                    <div className="books-overlay">
                                        <div className="books-overlay-text">{bookmark.book_name}</div>
                                        <Row>
                                            <Col>
                                            {bookmark.is_bookmarked === false ?<FontAwesomeIcon onClick={(e)=>this.addToBookmark(e,bookmark.catalogue_id)} icon={faBookmark} className="books-overlay-bookmark"/>
                                                :<FontAwesomeIcon onClick={(e)=>this.removeFromBookmark(e,bookmark.bookmarked_id)} icon={faBookmark} style={{color:'red'}} className="books-overlay-bookmark"/>}
                                            </Col>
                                            <Col>       
                                                   {bookmark.is_book_queued === false ? <FontAwesomeIcon onClick={(e)=>this.addToQueue(e,bookmark.book_name,bookmark.catalogue_id)} icon={faPlusCircle} className="books-overlay-atq"/>:
                                                     <FontAwesomeIcon onClick={(e)=>this.removeFromQueue(e,bookmark.added_bk_queue_id)} icon={faMinus} className="books-overlay-atq"/>}
                                            </Col>
                                        </Row>
                                    </div>
                                    
                                </Card>
                                <h6>{bookmark.book_name}</h6>
                                {/* <h6>{bookmark.publisher}</h6> */}
                                </Col> 
                            );
                                
                            })}
                            <ToastContainer />
                            </Row>
                </Container>
            </div>
                    
        )
    }


}