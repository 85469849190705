import React from 'react';
import './How_it_Works.css';

function HowItWorks() {
    return (
        <div>
        <div className = 'hiw-container' id='hiw'>
            <div className = 'hiw-title'>
                <h2 className = 'title3'>HOW IT WORKS.</h2>
            </div>
            <div className = 'vertical-line-divider-hiw'></div>
            <hr className = 'hiw-hz-line'></hr>
            <div className = 'hiw-details'>
                <p className = 'hiw-details-p'>
                Create a FREE account. Search and find the books you want. 
                Add them to your queue. Subscribe to a plan and ask for a delivery. 
                Receive delivery - return old books and get new books.

                Read peacefully.
                </p>
            </div>
        </div>
        
        </div>
    )
}

export default HowItWorks;
