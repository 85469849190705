import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './AboutUs.css';
import {faInstagram,faGooglePlusG,faTwitter,faLinkedin,faFacebook} from '@fortawesome/free-brands-svg-icons';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';

function AboutUs() {

    const fbClick = () => {
        window.open("https://www.facebook.com/Cllearning");
      };
      const extClick = () => {
        window.open("https://www.confidentliving.co.in");
      };
      const instaClick = () => {
        window.open("https://www.instagram.com/p/B6e4JTLJ8sm/");
      };
      const mailClick = () => {
        window.open("mailto:aanchal.agrawal@confidentliving.co.in");
      };

    return (
        <div id='about'>
        <div className = 'about-container'>
            <div className = 'about-title'>
                <h2 className = 'title4'>ABOUT US.</h2>
            </div>
            <div className = 'vertical-line-divider-about'></div>
            <hr className = 'about-hz-line'></hr>
            <div className = 'about-details'>
                <p className = 'about-details-p'>
                My Hangout library has been conceptualized and developed by Aanchal Agrawal. 
                Aanchal is a gold medalist from Banaras Hindu University, a trained child psychotherapist and a special educator. 
                She has more than 15 years of experience in working with children and is extremely passionate about developmental and learning processes in children. 
                She has a dedicated section on Parentree, which is one of the premier parenting website in India. 
                She has also been associated with Vibgyor High, India International, Redbridge, I Play I Learn and Little Elly for workshops and consultations. 
                She also contributes articles on various publications. In addition to Hangout Library, Aanchal also runs CL Learning which focusses on developing learning skills in children.

                As a mother, she understands the problem of reduced interest in reading habits in children and lack of public libraries in India.
                </p>
            </div>
        </div>
        <div className = 'about-container'>
            <div className = 'about-image'>
                    <img src = 'http://15.207.107.128:6200/images/founder.png'
                    alt = 'Personal Info' />
            </div>
            <div className = 'about-details'>
            <div className = 'about-details-p'>
                        <h3 className = 'card-title'>AANCHAL AGRAWAL</h3>
                        <h6 className = 'card-subtitle'>CEO and FOUNDER</h6>
                        <p className = 'card-details'>
                        Aanchal, a gold medalist from Banaras Hindu University, is a trained child psychotherapist and a special educator. 
                        In addition to Hangout Library, Aanchal also runs CL Learning (www.confidentliving.co.in) which focusses on developing learning skills in children.
                        </p>
                        <h6 className = 'card-subtitle'>Phone number: 9980300816</h6>
                        <div>
                        <ul class = 'socials'>
                            <li onClick={mailClick}><FontAwesomeIcon icon={faGooglePlusG} size='2x'></FontAwesomeIcon></li>
                            <li onClick={instaClick}><FontAwesomeIcon icon={faInstagram} size='2x'></FontAwesomeIcon></li>
                            {/* <li><FontAwesomeIcon icon={faLinkedin} size='2x'></FontAwesomeIcon></li> */}
                            <li onClick={fbClick}><FontAwesomeIcon icon={faFacebook} size='2x'></FontAwesomeIcon></li>
                            <li><FontAwesomeIcon icon={faTwitter} size='2x'></FontAwesomeIcon></li>
                            <li onClick={extClick}><FontAwesomeIcon icon={faSignOutAlt} size='2x'></FontAwesomeIcon></li>
                        </ul>
                        </div>
                        
                    </div>
            </div>
        </div>
        
        </div>
    )
}

export default AboutUs;
